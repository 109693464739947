import {TWalletTransaction, TWalletTransactionOption, TWalletTransactionStatus} from "../../interfaces/wallet";
import {
  actionColumn,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilter,
  enumFilterWithoutNull,
  gridAction
} from "../../patterns/list/listUtils";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Chip, Typography} from "@mui/material";
import React from "react";
import {TFunction} from "react-i18next";
import {
  cancelTransaction,
  downloadTransactionInvoice,
  downloadTransactionOrderForm,
  downloadTransactionProFormaInvoice,
  initStripePayment
} from "../../services/OperationService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {NavigateFunction} from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BlockIcon from "@mui/icons-material/Block";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InputIcon from '@mui/icons-material/Input';
import {GridValueGetterParams} from "@mui/x-data-grid-pro";
import {getExportDocument} from "../../common/methods/exportDocument";
import {paymentStoreReducer} from "../../common/methods/context-setter/paymentStoreReducer";
import {INIT_PAYMENT, UPDATE_COMPANY} from "../../common/methods/context-setter/globals";
import {changeEuroPerPoint} from "../../services/CompanyService";
import {globalStoreReducer, UPDATE} from "../../common/methods/context-setter/globalStoreReducer";

/** STATUS */
export const statusProps = (row: TWalletTransaction): ChipCellProps => {
  switch (row.status) {
  case TWalletTransactionStatus.ON_GOING:
    return {
      icon: <HelpIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.light",
      color: "neutral.main",
      backgroundColor: "ornament.main"
    }
  case TWalletTransactionStatus.PAID:
    return {
      icon: <CheckCircleIcon sx={{width: 18, height: 18}}/>,
      iconColor: "success.main",
      color: "success.dark",
      backgroundColor: "success.light",
      date: row.updatedAt ? new Date(row.updatedAt) : "-"
    }
  case TWalletTransactionStatus.ERROR:
    return {
      icon: <ErrorIcon sx={{width: 18, height: 18}}/>,
      iconColor: "warning.main",
      color: "warning.dark",
      backgroundColor: "warning.light",
    }
  case TWalletTransactionStatus.CANCELLED:
    return {
      icon: <CancelIcon sx={{width: 18, height: 18}}/>,
      iconColor: "error.main",
      color: "error.dark",
      backgroundColor: "error.light",
      date: row.updatedAt ? new Date(row.updatedAt) : "-"
    }
  }
}

export const statusColumnDefinition = (t: TFunction): GridEnrichedColDef => {
  
  return {
    ...columnDefinition(t, "operation_transactions", "status", 250),
    type: "singleSelect",
    filterOperators: enumFilterWithoutNull,
    valueOptions: Object.values(TWalletTransactionStatus).map(status => ({value: status, label: t(`operation_transactions.status.${status.toLowerCase()}_label`)})),
    renderCell: (item): JSX.Element => {
      const paid = statusProps(item.row);
      return <Chip icon={paid.icon}
        sx={{backgroundColor: paid.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: paid.iconColor}}}
        label={<Typography variant="body2" color={paid.color} sx={{fontWeight: "regular"}}>
          {t("operation_transactions.status." + item.value.toLowerCase(), {date: paid.date})}
        </Typography>}/>
    }
  }
}

/** OPTION */
export const optionProps = (row: TWalletTransaction): ChipCellProps => {
  switch (row.option) {
  case undefined:
  case TWalletTransactionOption.CB:
    return {
      icon: <CreditCardIcon sx={{width: 18, height: 18}}/>,
      iconColor: "ornament.light",
      color: "ornament.light",
      backgroundColor: "primaryLight.dark",
    }
  case TWalletTransactionOption.TRANSFER:
    return {
      icon: <AccountBalanceIcon sx={{width: 18, height: 18}}/>,
      iconColor: "ornament.light",
      color: "ornament.light",
      backgroundColor: "secondaryLight.dark",
    }
  }
}

export const optionColumnDefinition = (t: TFunction): GridEnrichedColDef => {
  return {
    ...columnDefinition(t, "operation_transactions", "option", 200, ColumnType.STRING, true, true),
    type: "singleSelect",
    filterOperators: enumFilter,
    valueGetter: (item: GridValueGetterParams): string => item.row.option,
    valueOptions: Object.values(TWalletTransactionOption).map(option => ({value: option, label: t(`operation_transactions.option.${option.toLowerCase()}`)})),
    renderCell: (item): JSX.Element => {
      const option = optionProps(item.row);
      return <Chip icon={option.icon}
        sx={{backgroundColor: option.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: option.iconColor}}}
        label={<Typography variant="body2" color={option.color} sx={{fontWeight: "regular"}}>
          {t("operation_transactions.option." + item.value.toLowerCase(), {date: option.date})}
        </Typography>}/>
    }
  }
}

/** DOWNLOAD */
// TODO: une fois les transactions typées, interdire le téléchargement de la facture d'un retrait cadeau
const downloadInvoice = (transaction: TWalletTransaction, navigate: NavigateFunction, setLoading: (loading: boolean) => void, setError: (error: string) => void, t: TFunction, STORE: StoreContext): void => {
  if (transaction.status == TWalletTransactionStatus.ERROR) {
    setError(t("operation_transactions.actions.download_error"));
    return;
  }
  if (transaction.status == TWalletTransactionStatus.CANCELLED) {
    setError(t("operation_transactions.actions.download_cancelled"));
    return;
  }

  setLoading(true);
  downloadTransactionInvoice(transaction.id)
    .then(getExportDocument)
    .catch((error) => {
      if (error.status === 404) setError(t("operation_transactions.actions.download_no_invoice"))
      else manageStringError(errorManager(error, t, STORE, navigate), setError, t)
    })
    .finally(() => setLoading(false));
}

export const downloadProFormaInvoice = (transaction: TWalletTransaction, navigate: NavigateFunction, setLoading: (loading: boolean) => void, setError: (error: string) => void, t: TFunction, STORE: StoreContext): void => {
  setLoading(true);
  downloadTransactionProFormaInvoice(transaction.id)
    .then(getExportDocument)
    .catch((error) => {
      setLoading(false);
      if (error.status === 404) setError(t("operation_transactions.actions.download_no_invoice"))
      else manageStringError(errorManager(error, t, STORE, navigate), setError, t)
    })
    .finally(() => setLoading(false));
}

export const downloadOrderForm = (transaction: TWalletTransaction, navigate: NavigateFunction, setLoading: (loading: boolean) => void, setError: (error: string) => void, t: TFunction, STORE: StoreContext): void => {
  setLoading(true);
  downloadTransactionOrderForm(transaction.id)
    .then(getExportDocument)
    .catch((error) => {
      setLoading(false);
      if (error.status === 404) setError(t("operation_transactions.actions.download_no_form"))
      else manageStringError(errorManager(error, t, STORE, navigate), setError, t)
    })
    .finally(() => setLoading(false));
}

export const handleInitPaymentChoice = (option: TWalletTransactionOption, pointsPurchase: string, companyId: string, euroPerPoint: string|null, walletId: string, navigate: NavigateFunction, STORE: StoreContext): Promise<void> => {
  const pointsPurchaseFormatted = (parseFloat(pointsPurchase).toFixed(2)).toString();
  if (euroPerPoint !== null) {
    changeEuroPerPoint(companyId, (parseFloat(euroPerPoint).toFixed(2)).toString()).then(content => {
      globalStoreReducer(STORE, {type: UPDATE_COMPANY, method: UPDATE, company: content})
    });
  }
  switch (option) {
  case TWalletTransactionOption.CB:
    return initStripePayment(pointsPurchaseFormatted, companyId)
      .then(content => {
        paymentStoreReducer(STORE, {type: INIT_PAYMENT, payment: {
          type: TWalletTransactionOption.CB,
          amount: pointsPurchaseFormatted,
          secret: content.stripeSecret,
          walletId: walletId
        }});
        navigate(PRIVATE_URL.TRANSACTION_PAYMENT_CONFIRM);
      })
  case TWalletTransactionOption.TRANSFER:
    paymentStoreReducer(STORE, {type: INIT_PAYMENT, payment: {
      type: TWalletTransactionOption.TRANSFER,
      amount: pointsPurchaseFormatted,
      walletId: walletId
    }});
    navigate(PRIVATE_URL.TRANSACTION_PAYMENT_CONFIRM);
    return Promise.resolve();
  }
}

export const handleCancelPayment = (transaction: TWalletTransaction|null, setReload: (reload: boolean) => void): Promise<void> => {
  if (transaction == null) {
    return Promise.resolve();
  }
  return cancelTransaction(transaction.id).then(() => setReload(true));
}

/** ACTIONS */
export const paymentActions = (t: TFunction, navigate: NavigateFunction, setLoading: (loading: boolean) => void, setError: (error: string) => void, STORE: StoreContext,
  cancelTransaction: (transaction: TWalletTransaction) => void,
  showBankInfo: (transaction: TWalletTransaction) => void,
  updateTransactionReference: (transaction: TWalletTransaction) => void): GridEnrichedColDef => {
  return actionColumn(params => {
    const actions = [];
    if (params.row.status == TWalletTransactionStatus.ON_GOING) {
      actions.push(
        gridAction(params, "download_pro_forma_invoice", t("operation_transactions.actions.download_pro_forma_invoice"), FileDownloadIcon,
          (row: TWalletTransaction) => downloadProFormaInvoice(row, navigate, setLoading, setError, t, STORE)),
        gridAction(params, "download_order_form", t("operation_transactions.actions.download_order_form"), FileDownloadIcon,
          (row: TWalletTransaction) => downloadOrderForm(row, navigate, setLoading, setError, t, STORE)),
        gridAction(params, "add_reference", t("operation_transactions.actions.add_reference"), InputIcon, updateTransactionReference),
      );

      if (params.row.option == TWalletTransactionOption.TRANSFER) {
        actions.push(gridAction(params, "show_bank_info", t("operation_transactions.actions.show_bank_info"), AccountBalanceIcon, showBankInfo));
      }
      actions.push(gridAction(params, "cancel_transaction", t("operation_transactions.actions.cancel_transaction"), BlockIcon, cancelTransaction));
    }
    if (params.row.status == TWalletTransactionStatus.PAID && params.row.odooOrderName != null) {
      actions.push(
        gridAction(params, "download_invoice", t("operation_transactions.actions.download_invoice"), EuroSymbolIcon,
          (row: TWalletTransaction) => downloadInvoice(row, navigate, setLoading, setError, t, STORE))
      );
    }
    return actions;
  });
}
