import {Box, Card, Checkbox, FormControlLabel, InputAdornment, Radio, TextField, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React, {useEffect, useState} from "react";
import {OperationShopPricingProps} from "./operationShopPricing";
import {TShopPricing} from "../../interfaces/shop";
import {useTranslation} from "react-i18next";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {putShop} from "../../services/ShopService";
import {manageStringError} from "../../common/methods/ApiService";

export default function OperationShopPricingUnique(props: OperationShopPricingProps): JSX.Element {
  const MIN_MAX_GIFTS = 50;

  const {shop, setShop, pricing, changePricing, saved, setError} = props;
  const {t} = useTranslation();  

  const [priceError, setPriceError] = useState<string|null>(null);
  const [maxError, setMaxError] = useState<string|null>(null);
  const [price, setPrice] = useState<string>("");
  const [hasMaxGifts, setHasMaxGifts] = useState<boolean>(true);
  const [maxGifts, setMaxGifts] = useState<string>(shop.giftsMax?.toString() ?? "");
  const [hideGiftPrice, setHideGiftPrice] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (shop.pricing === TShopPricing.UNIQUE) {
      setPrice(shop.priceMin?.toString()??"");
      setHasMaxGifts(shop.giftsMax !== undefined);
      setMaxGifts(shop.giftsMax?.toString() ?? "");
      setHideGiftPrice(shop.hidePricing);
    } else {
      setPrice("");
      setHasMaxGifts(false);
      setMaxGifts("");
      setHideGiftPrice(false);
    }
  }, [shop])

  useEffect(() => {
    if (!hasMaxGifts) {
      setMaxGifts("");
      setMaxError(null);
    }
  }, [hasMaxGifts])

  useEffect(() => {    
    setMaxError(null);
  }, [maxGifts])

  const savePriceRange = (): void => {
    setMaxError(null);
    setPriceError(null);
    setLoading(true);
    let hasError = false;
    if (price.length == 0) {
      setPriceError(t("operations.shop.price.unique.price_missing"));
      hasError = true;
    }
    if (maxGifts.length == 0 && hasMaxGifts) {
      setMaxError(t("operations.shop.price.unique.max_gifts_missing"));
      hasError = true;
    }
    if (parseInt(maxGifts) < MIN_MAX_GIFTS) {
      setMaxError(t("operations.shop.price.unique.max_gifts_minimum", {number: MIN_MAX_GIFTS}));
      hasError = true;
    }
    if (hasError) {
      setLoading(false);
      return;
    }

    putShop(shop.id, {pricing: TShopPricing.UNIQUE, priceMin: price, giftsMax: hasMaxGifts ? parseInt(maxGifts) : null, hidePricing: hideGiftPrice})
      .then(shop => setShop(shop))
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setLoading(false))
  }

  return (
    <Card sx={{border: "2px solid", boxShadow: 0, mb: 5,
      borderColor: saved === TShopPricing.UNIQUE ? "primary.main" : pricing === TShopPricing.UNIQUE ? "neutral.light" : "ornament.dark",
      backgroundColor: saved === TShopPricing.UNIQUE ? "alt.light" : "none"}}>
      <Box sx={{p: 6}}>
        <FormControlLabel sx={{mb: 1}} checked={pricing === TShopPricing.UNIQUE}
          onClick={(): void => changePricing(TShopPricing.UNIQUE)}
          control={<Radio sx={{fontSize: 24, p: "0px", mr: 4}}/>}
          label={<>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("operations.shop.price.unique.title")}</Typography>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: 400, mr: 0}} dangerouslySetInnerHTML={{__html: t("operations.shop.price.unique.subtitle")}} />
          </>}/>
      </Box>
      {pricing === TShopPricing.UNIQUE && <>
        <Box sx={{p: 6, display: "flex", flexDirection: "column", borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 2}}>
            {t("operations.shop.price.unique.price_description")}
          </Typography>
          <Box sx={{display: "flex", alignItems: priceError ? "center" : "flex-end", width: "100%"}}>
            <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
              <Typography variant="body2" color="neutral.main" sx={{mb: 0}}>
                {t("operations.shop.price.unique.price")}
              </Typography>
              <TextField
                sx={{"& input": {p: 3}}}
                fullWidth
                color="info"
                id="unique-price"
                placeholder={t("operations.shop.price.unique.price_placeholder")}
                value={price}
                onChange={(event): void => setPrice(event.target.value)}
                required
                disabled={loading}
                InputProps={{
                  lang: "fr-FR",
                  endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>
                }}
              />
            </Box>
          </Box>
          {priceError && <Box sx={{display: "flex", alignItems: "center", p: 0, mt: 0}}>
            <ErrorOutlineOutlinedIcon sx={{width: 18, height: 18, color: "error.main"}}/>
            <Typography variant="body2" color="error.main" sx={{ml: 0}}>{priceError}</Typography>
          </Box>}
          <FormControlLabel
            name="made_ethics"
            sx={{m: "unset", mt: 6}}
            control={<Checkbox checked={hasMaxGifts} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setHasMaxGifts(!hasMaxGifts)}/>}
            label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mx: 0}}>{t("operations.shop.price.unique.max_gifts_description")}</Typography>}
          />
          {hasMaxGifts && <>
            <Box sx={{display: "flex", mt: 3, alignItems: priceError ? "center" : "flex-end", width: "100%"}}>
              <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                <Typography variant="body2" color="neutral.main" sx={{mb: 0}}>
                  {t("operations.shop.price.unique.max_gifts")}
                </Typography>
                <TextField
                  sx={{"& input": {p: 3}}}
                  fullWidth
                  color="info"
                  id="unique-max-gifts"
                  placeholder={t("operations.shop.price.unique.max_gifts_placeholder")}
                  value={maxGifts}
                  onChange={(event): void => setMaxGifts(event.target.value)}
                  disabled={!hasMaxGifts || loading}
                  required={hasMaxGifts}
                  InputProps={{
                    lang: "fr-FR"
                  }}
                />
              </Box>
            </Box>
            {maxError && <Box sx={{display: "flex", alignItems: "center", p: 0, mt: 0}}>
              <ErrorOutlineOutlinedIcon sx={{width: 18, height: 18, color: "error.main"}}/>
              <Typography variant="body2" color="error.main" sx={{ml: 0}}>{maxError}</Typography>
            </Box>}
          </>}
          <FormControlLabel
            name="hide_gift_price"
            sx={{m: "unset", mt: 6, mb: 3}}
            control={<Checkbox checked={hideGiftPrice} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setHideGiftPrice(!hideGiftPrice)}/>}
            label={<Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mx: 0}}>{t("operations.shop.price.unique.hide_gifts_price")}</Typography>}
          />
        </Box>
        <Box sx={{py: 5, px: 6, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <PrimaryBigButton loading={loading} label={t("operations.shop.price.validate")} action={savePriceRange}/>
        </Box>
      </>}
    </Card>
  )
}
