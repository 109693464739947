import React, {useContext, useEffect, useState} from 'react';

// LIBRARY IMPORT
import {useTranslation} from "react-i18next";

// COMPONENTS IMPORT
import Box from '@mui/material/Box';
import {PrimaryBigButton, SecondarySmallButton} from "../../components/buttons/mainButton";

// FUNCTIONS AND STRUCT IMPORT
import {PRIVATE_URL, PUBLIC_URL} from '../../common/struct/urlManager'
import {Link, useNavigate} from "react-router-dom";
import {checkIsMobile, isAuthenticated, isSuperAdmin} from "../../common/struct/globalVar";
import {login} from "../../services/AuthenticationService";
import {errorManager, Violation} from '../../common/methods/ApiService';
import {Alert, Card, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {AppContext} from "../../App";
import {LOGIN} from "../../common/methods/context-setter/globals";
import {getAdmin} from "../../services/CompanyService";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import LogoutPage from "../../patterns/logout/logoutPage";


export default function Login(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext(AppContext);
  const [store] = STORE;

  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [error, setError] = useState<string|null>(null);
  const [resetSuccess, setResetSuccess] = useState(store.global.resetDone);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [stayConnected, setStayConnected] = useState(false);
  const [violations, setViolations] = useState<Violation[]>([]);
  useEffect(() => {
    document.title = t("tab_title.login");
  }, []);
  useEffect(() => {
    if (isAuthenticated()) {
      navigate(PRIVATE_URL.WELCOME, {replace: true});
    }

    const handleWindowSizeChange = (): void => setIsMobile(checkIsMobile());
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    return handleLogin();
  }

  const handleLogin = (): void => {
    setError(null);
    setViolations([]);
    setLoading(true);
    setResetSuccess(false);
    login(email.toLowerCase(), password, stayConnected)
      .then(() => getAdmin())
      .then(admin => {
        globalStoreReducer(STORE, {type: LOGIN, admin: admin??undefined});
        navigate(isSuperAdmin() ? PRIVATE_URL.SUPER_ADMIN_COMPANIES : PRIVATE_URL.LANDING_PAGE);
      })
      .catch(error => {        
        const errorResult = errorManager(error, t, STORE, navigate, false);
        if(typeof error === 'object' && Object(error).status === 403) {
          setError(Object(error).headers.get('X-ErrorMessage') + " " + t('error.attempt_remaining', {attempt: Object(error).headers.get('X-RateLimit-Remaining')}))
        }       
        else if(typeof error === 'object' && Object(error).status === 429) {
          setError(t('error.too_many_attempt', {count: Math.ceil(Object(error).headers.get('X-RateLimit-Retry-After') / 60)}))
        }
        else if (typeof errorResult === 'string' && error.status != 429) setError(errorResult);
        else if (Array.isArray(errorResult) && error.status != 429) setViolations(errorResult); 
      })
      .finally(() => setLoading(false))
  }

  const handleCheckbox = (event: any): void => {
    setStayConnected(event.target.checked);
  }

  return (
    <LogoutPage isMobile={isMobile}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: isMobile ? "90%" : "40%", height: "inherit", mx: "auto"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", mb: 6}}>
          {t("login.title")}
        </Typography>
        <form onSubmit={handleSubmit} style={{width: "100%"}}>
          {error && <Alert severity="error" variant="filled" sx={{mb: 3}}>{error}</Alert>}
          {resetSuccess && <Alert severity="success" variant="filled" sx={{mb: 3, fontWeight: "unset"}}>
            <span dangerouslySetInnerHTML={{__html: t("login.reset_success")}}/>
          </Alert>}
          <Card sx={{backgroundColor: "ornament.light", p: 6, mb: 6, border: "1px solid", borderColor: "ornament.dark"}}>
            <ElementInputWithLabel disabled={loading} type={INPUT_TYPES.EMAIL} label={t("login.form.email")} name="email" value={email} updateValue={setEmail} placeholder={t("login.form.email_placeholder")} violations={violations}/>
            <ElementInputWithLabel disabled={loading} type={INPUT_TYPES.PASSWORD} label={t("login.form.password")} name="password" value={password} updateValue={setPassword} placeholder={t("login.form.password_placeholder")} violations={violations}/>
            <FormControlLabel control={<Checkbox checked={stayConnected} onChange={handleCheckbox} sx={{fontSize: 24, py: "0px"}} />}
              label={<Typography variant="body2" color="neutral.main">{t("login.form.stay_connected")}</Typography>} />
            <PrimaryBigButton label={t("login.form.submit_button")} sx={{mt: 5, mb: 2, width: "100%"}} submit={true} loading={loading}/>
            <Box sx={{textAlign: "center"}}>
              <Typography variant="body2" color="primary.main" sx={{fontWeight: "medium"}} component={Link} to={PUBLIC_URL.FORGET_PASSWORD}>
                {t("login.form.forget_password")}
              </Typography>
            </Box>
          </Card>
        </form>
        <Box sx={{display: "flex", alignItems: "center", mb: 7}}>
          {!isMobile && <Typography variant="body2" sx={{fontWeight: "medium", mr: 2}}>
            {t("login.register_label")}
          </Typography>}
          <SecondarySmallButton label={t("login.register_button")} href={PUBLIC_URL.REGISTER}/>
        </Box>
      </Box>
    </LogoutPage>
  )
}
