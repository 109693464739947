import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import Page from "../../patterns/page/page";
import SelectCompany from "../selectCompany/selectCompany";
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import SuperAdminCompanies from './companies/superAdminCompanies';
import SuperAdminAdmins from './admins/superAdminAdmins';
import SuperAdminOperations from './operations/superAdminOperations';

export enum SuperAdminMenu {
  DEFAULT = "home",
  COMPANIES = "companies",
  ADMINS = "admins",
  OPERATIONS = "operations"
}

export default function SuperAdminHome(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const extractSelectedMenu = (): SuperAdminMenu|null => {
    switch (location.pathname) {
    case PRIVATE_URL.SUPER_ADMIN_HOME:
      return SuperAdminMenu.DEFAULT;
    case PRIVATE_URL.SUPER_ADMIN_COMPANIES:
      return SuperAdminMenu.COMPANIES;
    case PRIVATE_URL.SUPER_ADMIN_ADMINS:
      return SuperAdminMenu.ADMINS;
    case PRIVATE_URL.SUPER_ADMIN_OPERATIONS:
      return SuperAdminMenu.OPERATIONS;
    default:
      navigate(PRIVATE_URL.SUPER_ADMIN_COMPANIES);
      return null;
    }
  }

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());

  const initMenu = [{
    icon: ApartmentIcon,
    label: 'super_admin.companies.title',
    url: PRIVATE_URL.SUPER_ADMIN_COMPANIES
  },
  {
    icon: PersonIcon,
    label: 'super_admin.admins.title',
    url: PRIVATE_URL.SUPER_ADMIN_ADMINS
  },
  {
    icon: PersonIcon,
    label: 'super_admin.operations.title',
    url: PRIVATE_URL.SUPER_ADMIN_OPERATIONS
  },
  {
    icon: ApartmentIcon,
    label: 'super_admin.home.title',
    url: PRIVATE_URL.SUPER_ADMIN_HOME
  }];

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  return (
    <Page title={t('select_company.title')} menus={initMenu}>
      <>
        {selectedMenu === SuperAdminMenu.COMPANIES.toString() &&
              <SuperAdminCompanies />
        }
        {selectedMenu === SuperAdminMenu.ADMINS.toString() &&
              <SuperAdminAdmins />
        }
        {selectedMenu === SuperAdminMenu.OPERATIONS.toString() &&
              <SuperAdminOperations />
        }
        {selectedMenu === SuperAdminMenu.DEFAULT.toString() &&
              <SelectCompany />
        }
      </>
    </Page>
  );
}
